import {Component, HostListener, Inject} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {
  Equipment,
  EquipmentBulkUpdateRequest,
  EquipmentStatus,
  isPendingOrder,
  isPendingOrderOrOrdred,
  isReceived
} from "../../api/model/equipment";
import {HttpErrorResponse} from "@angular/common/http";
import {concat, EMPTY, merge, Observable} from "rxjs";
import {EquipmentService} from "../../api/equipment.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiError} from "../../api/model/common";

import {apiErrorToMessage, datepickerDefaultToFriday} from "../../util";
import {MatSnackBar} from "@angular/material/snack-bar";
import moment from "moment";
import * as _ from "lodash";

@Component({
  selector: 'app-bulk-edit-dialog',
  templateUrl: './bulk-edit-dialog.component.html',
  styleUrls: ['../add-edit-dialog/equipment-add-edit-dialog.component.sass', './bulk-edit-dialog.component.sass']
})
export class BulkEditDialogComponent {
  apiError: string | undefined;
  statuses: string[] = Object.values(EquipmentStatus);
  valueSets = {
    status: this.toValueSetString(e => e.status),
    responsible: this.toValueSetStringArray(e => e.responsible),
    estimatedShipByDate: this.toValueSetDate(e => e.estimatedShipByDate),
    receivedOnDate: this.toValueSetDate(e => e.receivedOnDate),
    purchaseOrderNumber: this.toValueSetString(e => e.purchaseOrderNumber),
    purchaseOrderDate: this.toValueSetDate(e => e.purchaseOrderDate),
    salesOrderConfirmationNumber: this.toValueSetString(e => e.salesOrderConfirmationNumber),
    salesOrderConfirmationDate: this.toValueSetDate(e => e.salesOrderConfirmationDate),
    invoiceNumber: this.toValueSetString(e => e.invoiceNumber),
    invoiceDate: this.toValueSetDate(e => e.invoiceDate),
    installedDate: this.toValueSetDate(e => e.installedDate),
    warrantyDate: this.toValueSetDate(e => e.warrantyDate),
  };
  bulkEditForm = new FormGroup({
    status: new FormControl(this.singleValueOrNull(this.valueSets.status) as EquipmentStatus),
    responsible: new FormControl(this.getInitialResponsibleValue(this.valueSets.responsible)),

    estimatedShipByDate: new FormControl(this.singleValueOrNull(this.valueSets.estimatedShipByDate)),
    receivedOnDate: new FormControl(this.singleValueOrNull(this.valueSets.receivedOnDate)),

    purchaseOrderNumber: new FormControl(this.singleValueOrNull(this.valueSets.purchaseOrderNumber)),
    purchaseOrderDate: new FormControl(this.singleValueOrNull(this.valueSets.purchaseOrderDate)),
    salesOrderConfirmationNumber: new FormControl(this.singleValueOrNull(this.valueSets.salesOrderConfirmationNumber)),
    salesOrderConfirmationDate: new FormControl(this.singleValueOrNull(this.valueSets.salesOrderConfirmationDate)),
    invoiceNumber: new FormControl(this.singleValueOrNull(this.valueSets.invoiceNumber)),
    invoiceDate: new FormControl(this.singleValueOrNull(this.valueSets.invoiceDate)),
    installedDate: new FormControl(this.singleValueOrNull(this.valueSets.installedDate)),
    warrantyDate: new FormControl({value: this.singleValueOrNull(this.valueSets.warrantyDate), disabled: true}),
  });

  constructor(
    private equipmentService: EquipmentService,
    public dialogRef: MatDialogRef<BulkEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BulkEditDialogData,
    private _snackBar: MatSnackBar,
  ) {
    dialogRef.disableClose = true;

    this.bulkEditForm.controls.status.valueChanges.subscribe(value => {
      if (isReceived(value) && this.bulkEditForm.controls.receivedOnDate.value == null && this.valueSets.receivedOnDate.filter(v => v != null).length == 0) {
        console.log("received On Date set to today, cus it was null", this.valueSets.receivedOnDate);
        this.bulkEditForm.controls.receivedOnDate.setValue(new Date(), {emitEvent: false});
        this.bulkEditForm.controls.receivedOnDate.markAsDirty();
      }
    });
    merge(
      this.bulkEditForm.controls.purchaseOrderNumber.valueChanges,
    ).subscribe(value => {
      if (value && this.bulkEditForm.value.status == EquipmentStatus.PENDING_ORDER) {
        if (this.bulkEditForm.controls.purchaseOrderDate.value == null && this.valueSets.purchaseOrderDate.filter(v => v != null).length == 0) {
          this.bulkEditForm.controls.purchaseOrderDate.setValue(new Date(), {emitEvent: false});
        }
        this.bulkEditForm.controls.status.setValue(EquipmentStatus.PENDING_INFO);
        this._snackBar.open('Status changed to PENDING_INFO as purchase order number was entered',
          undefined, {duration: 8000});
      }
    });
    merge(
      this.bulkEditForm.controls.estimatedShipByDate.valueChanges,
      this.bulkEditForm.controls.salesOrderConfirmationNumber.valueChanges,
    ).subscribe(value => {
      if (
        (isPendingOrder(this.bulkEditForm.value.status)) &&
        this.bulkEditForm.controls.estimatedShipByDate.value && this.bulkEditForm.controls.salesOrderConfirmationNumber.value
      ) {
        this.bulkEditForm.controls.status.setValue(EquipmentStatus.ORDERED);
        this.bulkEditForm.controls.status.markAsDirty();
        this._snackBar.open('Status changed to ORDERED as estimated ship by and SOC number fields were entered',
          undefined, {duration: 8000});
      }
    });
    this.bulkEditForm.controls.receivedOnDate.valueChanges.subscribe(value => {
      if (value && isPendingOrderOrOrdred(this.bulkEditForm.value.status)) {
        this.bulkEditForm.controls.status.setValue(EquipmentStatus.IN_STOCK);
        this.bulkEditForm.controls.status.markAsDirty();
        this._snackBar.open('Status changed to RECEIVED as received on date was entered',
          undefined, {duration: 8000});
      }
    });
    console.log("bulk edit value sets detected", this.valueSets)
  }

  singleValueOrNull<Type>(set: (Type)[]): Type | null {
    return set.length == 1 ? set[0] : null;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.bulkEditForm.dirty;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onPrimaryClick() {
    this.onSubmit()
  }

  onDeleteClick() {
    if (prompt(`Are you sure you want to delete ${this.data.equipment.length} items? Action is irreversible. Type 'yes' to continue`) == 'yes') {
      console.log("bulk edit deleting", this.data.equipment);
      concat(
        ...this.data.equipment.map(e => this.equipmentService.delete(e.id))
      ).subscribe({
        next: (eq) => {
          console.log("bulk edit deleted once", eq);
        },
        error: error => {
          console.log("bulk edit failed to delete", error)
          this.apiError = apiErrorToMessage(error);
          return EMPTY
        },
        complete: () => {
          console.log("bulk edit delete completed", this.apiError);
          if (!this.apiError) {
            this.dialogRef.close(true)
          }
        }
      });
    } else {
      alert("That wasn't a 'yes'... phew.");
    }
  }

  onSubmit() {
    this.apiError = undefined;

    const request = {} as EquipmentBulkUpdateRequest;
    request.ids = this.data.equipment.map(e => e.id)
    if (this.bulkEditForm.controls.status.dirty) request.status = this.bulkEditForm.value.status;
    if (this.bulkEditForm.controls.responsible.dirty) request.responsible = this.bulkEditForm.value.responsible;
    if (this.bulkEditForm.controls.estimatedShipByDate.dirty) request.estimatedShipByDate = this.bulkEditForm.value.estimatedShipByDate?.valueOf();
    if (this.bulkEditForm.controls.receivedOnDate.dirty) request.receivedOnDate = this.bulkEditForm.value.receivedOnDate?.valueOf();
    if (this.bulkEditForm.controls.purchaseOrderNumber.dirty) request.purchaseOrderNumber = this.bulkEditForm.value.purchaseOrderNumber;
    if (this.bulkEditForm.controls.purchaseOrderDate.dirty) request.purchaseOrderDate = this.bulkEditForm.value.purchaseOrderDate?.valueOf();
    if (this.bulkEditForm.controls.salesOrderConfirmationNumber.dirty) request.salesOrderConfirmationNumber = this.bulkEditForm.value.salesOrderConfirmationNumber;
    if (this.bulkEditForm.controls.salesOrderConfirmationDate.dirty) request.salesOrderConfirmationDate = this.bulkEditForm.value.salesOrderConfirmationDate?.valueOf();
    if (this.bulkEditForm.controls.invoiceNumber.dirty) request.invoiceNumber = this.bulkEditForm.value.invoiceNumber;
    if (this.bulkEditForm.controls.invoiceDate.dirty) request.invoiceDate = this.bulkEditForm.value.invoiceDate?.valueOf();
    if (this.bulkEditForm.controls.installedDate.dirty) request.installedDate = this.bulkEditForm.value.installedDate?.valueOf();
    this.equipmentService.bulkUpdate(request)
      .subscribe({
        next: (eq) => {
          console.log("dialog updated equipment", request);
          this.dialogRef.close(true);
        },
        error: error => {
          console.log("bulk edit failed", request, error)
          this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message;
          return EMPTY;
        }
      });
  }

  datePickerValueChange($event: Date, formControlChange: string) {
    datepickerDefaultToFriday(this.bulkEditForm.get(formControlChange), $event);
  }

  hint(values: (any | null)[]): string {
    if (values.length <= 1)
      return "same value on all";
    return `multiple values (${values.length})`;
  }

  tooltip(values: (string | null)[]): string {
    return values.map(v => v || "<empty>").join(", ");
  }

  tooltipArray(values: (string[] | null)[]): string {
    return values.map(v => v?.join(", ") || "<empty>").join("; ");
  }

  tooltipDate(values: (Date | null)[]): string {
    return this.tooltip(values.map(v => v && moment(v).format("YYYY-MM-DD")));
  }

  private toValueSetString(fn: (e: Equipment) => string | null): (string | null)[] {
    return [...new Set(this.data.equipment.map(fn))];
  }

  private toValueSetStringArray(fn: (e: Equipment) => string[] | null): (string[] | null)[] {
    return _.uniqWith(this.data.equipment.map(fn), _.isEqual);
  }

  private toValueSetDate(fn: (e: Equipment) => Date | null): (Date | null)[] {
    return [...new Set(
      this.data.equipment.map(fn)
        .map(v => v && moment(v).startOf('day').valueOf() || null)
    )].map(v => v && new Date(v) || null);
  }

  private getInitialResponsibleValue(responsibleValueSet: (string[] | null)[]): string[] {
    return [...new Set(responsibleValueSet.flatMap(value => value || []))];
  }
}

export interface BulkEditDialogData {
  equipment: Equipment[];
}
